

































































import Vue from 'vue'

import { Order } from '@/types'
import { truncate } from 'lodash'
import { mapActions } from 'vuex'
import { getCurrencySign } from '@/mixins/UseCurrency'
import { StatusColor } from '@/consts'

export default Vue.extend({
	name: 'OrderItem',
	props: {
		order: {
			required: true,
			type: Object as Vue.PropType<Order>,
		},
	},
	data: () => ({
		statusColors: StatusColor,
	}),
	computed: {
		currencySign(): string {
			return this.getCurrencySign(this.order.vCurrency)
		},
		statusColor(): string {
			if (!this.order) return ''
			return this.statusColors[this.order.eStatus]
		},
	},
	methods: {
		truncate,
		getCurrencySign,
		...mapActions('carts', ['addToCart', 'removeCartItem']),
	},
})
