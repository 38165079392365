






import Vue from 'vue'
import Orders from './Orders.vue'
export default Vue.extend({
	name: 'GuestOrders',
	components: {
		Orders,
	},
})
