














































import Vue from 'vue'

import { Order } from '@/types'
import { Pagination } from 'vuelpers'
import { Accessors } from 'vue/types/options'
import { mapActions, mapGetters } from 'vuex'

import OrderItem from '@/components/OrderItem.vue'
import { ordersWith } from '@/queries'
import OrderView from '@/components/OrderView.vue'
import { APP_META } from '@/consts'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
	name: 'Orders',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Orders | ${APP_META.title}`,
		}
	},
	components: {
		OrderItem,
		OrderView,
	},
	created() {
		this.fetchOrders({
			perPage: 5,
			with: ordersWith,
		})
		this.$syncStack({
			name: 'My orders',
			path: this.$route.path,
		})
	},
	computed: {
		...(mapGetters('orders', ['$orders']) as Accessors<{
			$orders: Pagination<Order>
		}>),
	},
	methods: {
		...mapActions('orders', ['fetchOrders']),
		onShowMore() {
			this.fetchOrders({
				with: ordersWith,
				perPage: this.$orders.perPage,
				page: this.$orders.currentPage + 1,
			})
		},
		onViewOrder(order: Order) {
			this.$store.commit('orders/SET', {
				orderView: {
					dialog: true,
					data: order,
				},
			})
		},
	},
})
